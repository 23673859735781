<template>
    <div class="d-flex pe-2 border-bottom">
        <div class="flex-fill d-flex align-items-center">
            <ShowSiteTreeBtn :table-name="'pages'" :id="0" :on-page-select="onPageSelect"/>
            <PageEditorToolbarContentDropdown/>
            <PageEditorToolbarContentAreaBtn/>
            <PageEditorToolbarContentBtn/>
        </div>

        <div>
            <PageEditorToolbarEditBtn/>
            <PageEditorToolbarEditOffcanvas/>

            <PageEditorToolbarShowBtn/>
        </div>
    </div>
</template>

<script>
import PageEditorToolbarContentDropdown from "./PageEditorToolbarContentDropdown.vue"
import PageEditorToolbarContentAreaBtn from "./PageEditorToolbarContentAreaBtn.vue"
import PageEditorToolbarContentBtn from "./PageEditorToolbarContentBtn.vue"
import PageEditorToolbarShowBtn from "./PageEditorToolbarShowBtn.vue"
import ShowSiteTreeBtn from "../../common/ShowSiteTreeBtn.vue"
import PageEditorToolbarEditOffcanvas from "./PageEditorToolbarEditOffcanvas.vue"
import PageEditorToolbarEditBtn from "./PageEditorToolbarEditBtn.vue"

export default {
    name: "PageEditorToolbar",
    components: {
        PageEditorToolbarEditBtn,
        PageEditorToolbarEditOffcanvas,
        ShowSiteTreeBtn,
        PageEditorToolbarShowBtn,
        PageEditorToolbarContentBtn,
        PageEditorToolbarContentAreaBtn,
        PageEditorToolbarContentDropdown
    },
    computed: {
        pageContentId() {
            return this.$store.state.pageEditor.activePageContentId
        }
    },
    methods: {
        onPageSelect(o) {
            this.$router.push({
                name: 'pageEditor',
                params: {
                    pageId: o.pageId
                }
            })
        }
    }
}
</script>

<style scoped>

</style>