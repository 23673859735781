export default {
    namespaced: true,
    state: {
        groups: [],
        components: [],
        openGroupName: undefined,
        open: false
    },
    mutations: {
        groups(state, groups) {
            state.groups = groups
        },
        components(state, components) {
            state.components = components
        },
    },
    actions: {
        init({dispatch}) {
            dispatch('setGroups')
            dispatch('setGroupsComponents')
        },
        setGroups({commit, rootState}) {
            if (rootState.components) {
                let groups = [
                    'Website',
                    'eCommerce',
                    'Add ons',
                    'Marketing',
                    'Forms',
                    'Themes',
                    'Modules',
                ]
                
                rootState.components.items.forEach((obj) => {
                    if (
                        !!obj.navigationGroup
                        && (
                            !obj.devOnly
                            || rootState.betaMode
                        )
                    ) {
                        if (groups.indexOf(obj.navigationGroup) === -1) {
                            groups.push(obj.navigationGroup)
                        }
                    }
                })
                
                commit('groups', groups)
            }
        },
        setGroupsComponents({commit, rootState, rootGetters}) {
            if (rootState.components) {
                let user = rootState.user.user
                let components = []
                
                rootState.components.items.forEach((obj) => {
                    if (
                        !!obj.navigationGroup
                        && (
                            !obj.devOnly
                            || rootState.betaMode
                        )
                    ) {
                        if (
                            user.administrator
                            || (
                                user.access?.__formAccess
                                && obj.navigationGroup === 'Forms'
                            )
                        ) {
                            components.push(obj)
                            return
                        }
                        
                        const privileges = rootGetters['user/access'](obj.tableName)
                        if (privileges) {
                            let found = false
                            
                            // Check that each enabled  is enabled at the component level.
                            // This fixes an issue where a privilege (e.g. archive) is applied to a user group but
                            // the component's option (e.g. useArchive) is disabled. When this is that case there's
                            // no archive option presented in roles so there's no way to revoke the privilege.
                            for (const privilege in privileges) {
                                if (!found) {
                                    switch (privilege) {
                                        case 'add':
                                            if (obj.addNew) found = true
                                            break
                                        case 'archive':
                                            if (obj.useArchive) found = true
                                            break
                                        case 'delete':
                                            if (obj.deleteOption) found = true
                                            break
                                        case 'edit':
                                            if (obj.updateOption) found = true
                                            break
                                        case 'publish':
                                            if (obj.showStatus) found = true
                                    }
                                }
                            }
                            
                            if (found) {
                                components.push(obj)
                            }
                        }
                    }
                })
                
                if (
                    components.find(o => o.tableName === 'pages')
                    && components.find(o => o.tableName === 'content')
                ) {
                    
                    components.unshift({
                        title: 'Site tree',
                        tableName: 'site_tree',
                        navigationGroup: 'Website'
                    })
                    
                    if (rootState.pageEditorMode) {
                        components.unshift({
                            title: 'Editor',
                            tableName: 'editor',
                            navigationGroup: 'Website'
                        })
                    }
                }
                
                if (user.administrator) {
                    components.unshift({
                        title: 'Editor',
                        tableName: 'themes_editor',
                        navigationGroup: 'Themes',
                    })
                }
                
                // Sort by title
                //components = components.sort((a, b) => (a.title > b.title) ? 1 : -1)
                
                commit('components', components)
            }
        }
    }
}