var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.offcanvasTitle
    ? _c(
        "div",
        {
          staticClass: "offcanvas offcanvas-end",
          attrs: {
            tabindex: "-1",
            id: "offcanvasPageEditorEditPage",
            "aria-labelledby": "offcanvasPageEditorEditPageLabel",
          },
        },
        [
          _c("div", { staticClass: "offcanvas-header" }, [
            _c(
              "h5",
              {
                staticClass: "offcanvas-title",
                attrs: { id: "offcanvasPageEditorEditPageLabel" },
              },
              [_vm._v(_vm._s(_vm.offcanvasTitle))]
            ),
            _vm._v(" "),
            _c("button", {
              staticClass: "btn-close",
              attrs: {
                type: "button",
                "data-bs-dismiss": "offcanvas",
                "aria-label": "Close",
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "offcanvas-body pb-5" },
            [
              _c("PageEditorEditFormAndSidebar", {
                key: _vm.tableName + "." + _vm.id,
                attrs: { "table-name": _vm.tableName, id: _vm.id },
              }),
              _vm._v(" "),
              _c("p", { staticClass: "pb-2" }),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "offcanvas-footer" }),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }