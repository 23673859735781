<template>
    <div class="d-flex flex-column h-100 overflow-hidden">
        <PageEditorToolbar/>

        <div v-if="pageId" ref="container" class="flex-fill d-flex h-100 overflow-hidden">
            <div class="flex-fill position-relative overflow-hidden w-100 h-100 d-flex">
                <PageEditorIframe :page-id="pageId" :key="pageId"/>

                <template v-if="contentAreas.length" v-for="contentArea in contentAreas">
                    <PageEditorContentAreaIframe :content-area="contentArea" :key="contentArea.name"/>

                    <template v-for="o in contentAreaContent(contentArea.name)">
                        <PageEditorContentIframe :content-item="o" :key="o.pageContentId"/>
                    </template>
                </template>
            </div>

            <!-- Bottom padding wasn't scrolling, so I've removed it here and it's added to the child elements -->
            <aside v-if="selectedContentAreaName || activePageContentId" class="p-3 pb-0 border-start overflow-y-scroll"
                   style="flex: 0 0 250px">
                <!--<pre><small><small>{{ {
                    hoverContentAreaName,
                    selectedContentAreaName,
                    highlightContentAreaName,
                    hoverPageContentId,
                    activePageContentId
                } }}</small></small></pre>-->
                <PageEditorContentAreaPanel v-if="selectedContentAreaName"/>
                <PageEditorContentPanel v-else-if="activePageContentId"/>
            </aside>
        </div>

        <PageEditorContentAreaOverlay v-if="hoverContentAreaName" :key="hoverContentAreaName"/>
        <PageEditorContentOverlay v-if="hoverPageContentId" :key="hoverPageContentId"/>
    </div>
</template>

<script>
import PageEditorContentOverlay from "./PageEditorContentOverlay.vue"
import PageEditorContentAreaOverlay from "./PageEditorContentAreaOverlay.vue"
import Form from "../../common/form/Form.vue"
import PageEditorContentIframe from "./PageEditorContentIframe.vue"
import PageEditorContentPanel from "./PageEditorContentPanel.vue"
import PageEditorContentAreaIframe from "./PageEditorContentAreaIframe.vue"
import PageEditorContentAreaPanel from "./PageEditorContentAreaPanel.vue"
import PageEditorToolbar from "./PageEditorToolbar.vue"
import PageEditorIframe from "./PageEditorIframe.vue"

export default {
    name: "PageEditor",
    components: {
        PageEditorIframe,
        PageEditorToolbar,
        PageEditorContentAreaPanel,
        PageEditorContentAreaIframe,
        PageEditorContentPanel,
        PageEditorContentIframe,
        Form,
        PageEditorContentAreaOverlay,
        PageEditorContentOverlay
    },
    computed: {
        pageEditor() {
            return this.$store.state.pageEditor
        },
        pageId() {
            return this.pageEditor.pageId
        },
        contentAreas() {
            return this.pageEditor.contentAreas
        },
        selectedContentAreaName() {
            return this.pageEditor.selectedContentAreaName
        },
        hoverContentAreaName() {
            return this.pageEditor.hoverContentAreaName
        },
        highlightContentAreaName() {
            return this.pageEditor.highlightContentAreaName
        },
        activePageContentId() {
            return this.pageEditor.activePageContentId
        },
        hoverPageContentId() {
            return this.pageEditor.hoverPageContentId
        }
    },
    destroyed() {
        this.$store.commit('pageEditor/pageId', 0)
    },
    watch: {
        $route: {
            // This doesn't work because the VueX module's init has yet to be called
            immediate: true,
            handler(to, from) {
                if (to.params?.pageId) {
                    this.$store.commit('pageEditor/pageId', parseInt(to.params?.pageId))
                }

                this.$store.commit('pageEditor/selectedContentAreaName', to.query.area)
                this.$store.commit('pageEditor/activePageContentId', parseInt(to.query.id))
            }
        }
    },
    methods: {
        contentAreaContent(name) {
            return this.pageEditor.contentItems.filter(o => o.contentAreaName === name)
        }
    }
}
</script>

<style scoped>

</style>