import { render, staticRenderFns } from "./SearchFilterItemResetBtn.vue?vue&type=template&id=669472c9&scoped=true&"
import script from "./SearchFilterItemResetBtn.vue?vue&type=script&lang=js&"
export * from "./SearchFilterItemResetBtn.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "669472c9",
  null
  
)

export default component.exports