var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex pe-2 border-bottom" }, [
    _c(
      "div",
      { staticClass: "flex-fill d-flex align-items-center" },
      [
        _c("ShowSiteTreeBtn", {
          attrs: {
            "table-name": "pages",
            id: 0,
            "on-page-select": _vm.onPageSelect,
          },
        }),
        _vm._v(" "),
        _c("PageEditorToolbarContentDropdown"),
        _vm._v(" "),
        _c("PageEditorToolbarContentAreaBtn"),
        _vm._v(" "),
        _c("PageEditorToolbarContentBtn"),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      [
        _c("PageEditorToolbarEditBtn"),
        _vm._v(" "),
        _c("PageEditorToolbarEditOffcanvas"),
        _vm._v(" "),
        _c("PageEditorToolbarShowBtn"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }