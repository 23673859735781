<template>
  <span class="ms-1 d-flex">
      <template v-if="filter.filterData.columnName && filter.filterData.condition">
          <!-- The email type cannot use type="email" else the value is null until the field validates -->
          <input v-if="isActive(['currency', 'email', 'number', 'tel', 'text', 'textarea', 'texteditor', 'url'])"
                 v-model="value" class="form-control"/>

          <select v-if="isActive(['checkbox'])" v-model="value" class="form-select">
              <option v-for="option in checkboxOptions" :value="option.value">
                  {{ option.text }}
              </option>
          </select>

          <input v-if="isActive(['color'])" v-model="value" class="form-control" maxlength="7" minlength="7"
                 placeholder="#000000" size="8">

          <input v-if="isActive(['date'])" v-model="value" class="form-control" placeholder="dd/mm/yyyy" size="12">

          <input v-if="isActive(['dateDate'])" v-model="value" class="form-control" type="date">

          <input v-if="isActive(['datetime-local'])" v-model="value" class="form-control" type="datetime-local">
          <!-- todo
          <span v-if="isActive(['dateRange'])">
              <input
                      class="form-control"
                      v-model="value.from"
                      placeholder="dd/mm/yyyy"
                      size="12"
                      type="text"
              >
              to
              <input
                      class="form-control"
                      v-model="value.to"
                      placeholder="dd/mm/yyyy"
                      size="12"
                      type="text"
              >
          </span>
          -->

          <SearchFilterFieldInternalLink v-if="isActive(['internalLink'])" :filter="filter" :view-id="viewId"
                                         style="display: flex"/>

          <select v-if="isActive(['password'])" v-model="value" class="form-select">
              <option v-for="option in passwordOptions" :value="option.value">
                  {{ option.text }}
              </option>
          </select>

          <input v-if="isActive(['range'])" v-model="value" class="form-control" type="number"/>

          <SearchFilterFieldCategory v-if="isActiveRelationshipField"
                                     v-model="value" :filter="filter" :listing-name="listingName" :view-id="viewId"/>

          <SearchFilterFieldSelect v-if="isActive(['select'])" :filter="filter" :listing-name="listingName"
                                   :view-id="viewId"/>

          <input v-if="isActive(['time'])" v-model="value" class="form-control" type="time">
      </template>

      <SearchFilterItemResetBtn :filter-id="filter.id" :view-id="viewId" :filter="filter"/>

      <!--<pre>{{ filters.advancedFilter }}</pre>-->
  </span>
</template>

<script>
import SearchFilterItemResetBtn from './SearchFilterItemResetBtn'
import SearchFilterFieldInternalLink from './SearchFilterFieldInternalLink'
import SearchFilterFieldSelect from './SearchFilterFieldSelect'
import SearchFilterFieldCategory from './SearchFilterFieldCategory'

export default {
    name: "SearchFilterField",
    components: {
        SearchFilterItemResetBtn,
        SearchFilterFieldInternalLink,
        SearchFilterFieldSelect,
        SearchFilterFieldCategory,
    },
    props: {
        listingName: String,
        filter: Object,
        viewId: String,
    },
    data() {
        return {
            checkboxOptions: [
                {
                    text: 'Select…'
                }, {
                    value: 1,
                    text: 'Checked'
                }, {
                    value: 0,
                    text: 'Not checked'
                }
            ],
            passwordOptions: [
                {
                    text: 'Select…'
                }, {
                    value: 'is set',
                    text: 'Set'
                }, {
                    value: 'is not set',
                    text: 'Not set'
                }
            ]
        }
    },
    computed: {
        filters() {
            return this.$store.state[this.viewId].filters
        },
        value: {
            get() {
                return this.filters.advancedFilter[this.filter.id]
            },
            set(val) {
                this.$store.commit(this.viewId + '/setFilter', {
                    id: this.filter.id,
                    value: val
                })
            }
        },
        isActiveRelationshipField() {
            if (
                this.filter.filterData.type === 'relationshipOneToMany'
                && (
                    this.filter.filterData.condition === 'is associated to'
                    || this.filter.filterData.condition === 'is not associated to'
                )
            ) {
                return true
            }

            if (
                this.filter.filterData.type === 'relationshipManyToMany'
                && (
                    this.filter.filterData.condition === 'is associated to many'
                    || this.filter.filterData.condition === 'is not associated to many'
                )
            ) {
                return true
            }

            return false
        }
    },
    methods: {
        isActive(types) {
            return types.indexOf(this.filter.filterData.type) > -1
                && this.filter.filterData.condition !== 'is set'
                && this.filter.filterData.condition !== 'is not set'

        }
    }
}
</script>

<style scoped>
.search-filters-field {
    display: flex;
}

.search-filters-field .well {
    margin: 0;
}
</style>