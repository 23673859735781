var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("Form", {
    attrs: {
      "form-id": _vm.formId,
      "show-field-names": true,
      "show-legends": true,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }