<template>
    <Button @click.native="openOffcanvas" class="rounded-0"><i class="bi bi-pencil"></i></Button>
</template>

<script>
import {Offcanvas} from "bootstrap"

import Button from "../../common/Button.vue"

export default {
    name: "PageEditorToolbarEditBtn",
    components: {
        Button
    },
    methods: {
        openOffcanvas() {
            const offcanvas = new Offcanvas('#offcanvasPageEditorEditPage')
            offcanvas.show()
        }
    }
}
</script>

<style scoped>

</style>