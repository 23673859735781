var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-8" },
      [_c("PageEditorEditForm", { attrs: { "form-id": _vm.formId } })],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-4" },
      [
        _c("FormSidebar", {
          attrs: {
            "form-id": _vm.formId,
            "show-archive-btn": true,
            "show-duplicate-btn": true,
            "show-history-btn": true,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }