<template>
    <div class="row">
        <div class="col-8">
            <PageEditorEditForm :form-id="formId"/>
        </div>
        <div class="col-4">
            <FormSidebar :form-id="formId" :show-archive-btn="true" :show-duplicate-btn="true"
                         :show-history-btn="true"/>
        </div>
    </div>
</template>

<script>
import Form from "../../common/form/Form.vue"
import FormSidebar from "../../common/form/FormSidebar.vue"
import PageEditorEditForm from "./PageEditorEditForm.vue"

export default {
    name: "PageEditorEditFormAndSidebar",
    components: {
        PageEditorEditForm,
        FormSidebar,
        Form
    },
    props: {
        tableName: String,
        id: Number
    },
    computed: {
        formId() {
            return 'form:' + this.tableName + ':' + this.id
        }
    },
    created() {
        this.$store.dispatch('forms/createForm', {
            tableName: this.tableName,
            id: this.id,
            onInit: () => {
                let fields = this.$store.state[this.formId].fields

                if (this.tableName === 'content') {
                    fields = fields.filter(o => o.columnName !== 'contentType')
                } else { // pages
                    fields = fields.filter(o => o.columnName !== 'content' && o.columnName !== 'templateId')
                }

                this.$store.state[this.formId].fields = fields
            }
        })
    },
}
</script>

<style scoped>

</style>