<template>
    <div>
        <!-- Nav tabs -->
        <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item" role="presentation" @click.prevent="activeTab = 'preview'">
                <a class="nav-link" :class="{ active: activeTab === 'preview' }" href="#">Preview</a>
            </li>
            <li class="nav-item" role="presentation" @click.prevent="activeTab = 'html'">
                <a class="nav-link" :class="{ active: activeTab === 'html' }" href="#">HTML</a>
            </li>
        </ul>

        <!-- Tab panes -->
        <div class="tab-content">
            <div v-if="activeTab === 'preview'" id="preview" :class="{ active: activeTab === 'preview' }"
                 class="tab-pane" role="tabpanel">
                <iframe ref="iframe" :srcdoc="iframeHtml" style="width: 100%; min-height: 250px; border: solid #ddd; border-width: 0 1px 1px 1px"/>
            </div>

            <div v-if="activeTab === 'html'" id="html" :class="{ active: activeTab === 'html' }" class="tab-pane"
                 role="tabpanel">
                <FormControlTypeCodeEditor :current-data="currentData" :field="field" :form-id="formId"/>
            </div>
        </div>
    </div>
</template>

<script>
import FormControlTypeCodeEditor from '../form-control-type/FormControlTypeCodeEditor'

export default {
    name: "FormControlEmailMessageHtmlBody",
    components: {
        FormControlTypeCodeEditor,
    },
    props: {
        formId: String,
        currentData: Object,
        field: Object,
    },
    data() {
        return {
            activeTab: 'preview'
        }
    },
    computed: {
        tableName() {
            return this.$store.state[this.formId].tableName
        },
        iframeHtml() {
            return this.currentData[this.field.name]
        }
    },
    created() {
        // Hide the required label
        this.field.required = false
    },
    mounted() {
        let iframe = this.$refs.iframe
        iframe.onload = () => {
            iframe.style.height = iframe.contentWindow.document.body.offsetHeight + 'px'
        }
    }
}
</script>

<style scoped>

</style>