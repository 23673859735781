var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    { ref: "form" },
    _vm._l(_vm.fields, function (field, i) {
      return _c("FormGroup", {
        key: i,
        staticClass: "mb-3",
        attrs: { "form-id": _vm.formId, field: field, "hide-help-text": true },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }