export default {
    namespaced: true,
    state: {
        data: {},
        valueSaveTimeoutPromise: 0,
    },
    getters: {
        typesOptions: (state) => (type, fieldId) => {
            
            // The "<Collection title> items" option sets the fieldId to zero.
            // This is used to directly target items within the collection.
            if (fieldId === 0) {
                type = 'relationshipManyToMany'
            }
            
            let options = []
            switch (type) {
                case 'checkbox':
                    options = [
                        {
                            value: 'is checked',
                            text: 'is checked'
                        },
                        {
                            value: 'is not checked',
                            text: 'is not checked'
                        }
                    ]
                    break
                
                case 'color':
                case 'range':
                    options = [
                        {
                            value: 'is',
                            text: 'is'
                        },
                        {
                            value: 'is not',
                            text: 'is not'
                        }
                    ]
                    break
                
                case 'custom':
                case 'customscript':
                    break
                
                case 'date':
                case 'dateDate':
                case 'datetime-local':
                case 'time':
                    options = [
                        {
                            value: 'date is',
                            text: 'is'
                        },
                        {
                            value: 'date is not',
                            text: 'is not'
                        },
                        {
                            value: 'date is after',
                            text: 'is after'
                        },
                        {
                            value: 'date is before',
                            text: 'is before'
                        },
                        /*{
                            value: 'date is in the last',
                            text: 'is in the last'
                        },
                        {
                            value: 'date is not in the last',
                            text: 'is not in the last'
                        }/*,
                        {
                            value: 'date is in the range',
                            text: 'is in the range'
                        }*/
                    ]
                    break
                
                case 'file':
                case 'image':
                    options = [
                        {
                            value: 'has file',
                            //text: 'has file'
                            text: 'exists'
                        },
                        {
                            value: 'does not have file',
                            //text: 'does not have file'
                            text: 'does not exist'
                        }
                    ]
                    break
                
                case 'hidden':
                    break
                
                case 'internalLink':
                    options = [
                        {
                            value: 'is',
                            text: 'is'
                        },
                        {
                            value: 'is not',
                            text: 'is not'
                        }
                    ]
                    break
                
                case 'number':
                case 'currency':
                    options = [
                        {
                            value: 'is',
                            text: 'is'
                        },
                        {
                            value: 'is not',
                            text: 'is not'
                        },
                        {
                            value: 'is less than',
                            text: 'is less than'
                        },
                        {
                            value: 'is greater than',
                            text: 'is greater than'
                        }
                    ]
                    break
                
                case 'password':
                    options = [
                        {
                            value: 'is',
                            text: 'is'
                        }
                    ]
                    break
                
                case 'relationshipOneToMany':
                    options = [
                        {
                            value: 'is associated to',
                            text: 'is'
                        },
                        {
                            value: 'is not associated to',
                            text: 'is not'
                        },
                        {
                            value: 'is set',
                            text: 'is set'
                        },
                        {
                            value: 'is not set',
                            text: 'is not set'
                        }
                    ]
                    break
                
                case 'relationshipManyToMany':
                    options = [
                        {
                            value: 'is associated to many',
                            text: 'is'
                        },
                        {
                            value: 'is not associated to many',
                            text: 'is not'
                        }
                    ]
                    break
                
                case 'select':
                    options = [
                        {
                            value: 'select is',
                            text: 'is'
                        },
                        {
                            value: 'select is not',
                            text: 'is not'
                        }
                    ]
                    break
                
                case 'email':
                case 'tel':
                case 'text':
                case 'textarea':
                case 'url':
                    options = [
                        {
                            value: 'contains',
                            text: 'contains'
                        },
                        {
                            value: 'does not contain',
                            text: 'does not contain'
                        },
                        {
                            value: 'is',
                            text: 'is'
                        },
                        {
                            value: 'is not',
                            text: 'is not'
                        },
                        {
                            value: 'starts with',
                            text: 'starts with'
                        },
                        {
                            value: 'does not start with',
                            text: 'does not start with'
                        },
                        {
                            value: 'ends with',
                            text: 'ends with'
                        },
                        {
                            value: 'does not end with',
                            text: 'does not end with'
                        }
                    ]
                    break
                
                case 'texteditor':
                    options = [
                        {
                            value: 'contains',
                            text: 'contains'
                        },
                        {
                            value: 'does not contain',
                            text: 'does not contain'
                        }
                    ]
                    break
            }
            
            return options
        }
    },
    actions: {
        init({state, dispatch}, {contentId, refresh}) {
            if (
                state.data[contentId] === undefined
                || refresh
            ) {
                dispatch('request/get', {
                    url: 'api/component/collection_filter',
                    params: {
                        contentId: contentId
                    }
                }, {root: true})
                    .then((obj) => {
                        this._vm.$set(state.data, contentId, obj.data.items)
                    })
            }
        },
        addFilter({dispatch}, {contentId}) {
            return dispatch('itemData/insert', {
                tableName: 'collection_filter',
                data: {
                    contentId: contentId,
                    operator: ''
                }
            }, {root: true})
                .then(() => {
                    dispatch('init', {
                        contentId: contentId,
                        refresh: true
                    })
                })
        },
        deleteFilter({dispatch}, {filterId, contentId}) {
            return dispatch('request/delete', {
                url: 'api/component/collection_filter/' + filterId,
            }, {root: true})
                .then(() => {
                    dispatch('init', {
                        contentId: contentId,
                        refresh: true
                    })
                })
        },
        updateItem({state, dispatch}, {item, oldItem}) {
            switch (true) {
                case item.fieldId !== oldItem.fieldId:
                    item.operator = ''
                    item.value = null
                    
                    return dispatch('itemData/update', {
                        tableName: 'collection_filter',
                        id: item.id,
                        data: {
                            fieldId: item.fieldId,
                            operator: '',
                            value: null
                        }
                    }, {root: true})
                
                case item.operator !== oldItem.operator:
                    return dispatch('itemData/update', {
                        tableName: 'collection_filter',
                        id: item.id,
                        data: {
                            operator: item.operator,
                        }
                    }, {root: true})
                
                case item.value !== oldItem.value:
                    // todo I've disabled this because it resulted in changes not saving if the user refreshed the page
                    //      immediately after making a change
                    // To avoid excessive save requests,
                    //clearTimeout(state.valueSaveTimeoutPromise)
                    //state.valueSaveTimeoutPromise = setTimeout(() => {
                    dispatch('itemData/update', {
                        tableName: 'collection_filter',
                        id: item.id,
                        data: {
                            value: item.value,
                        }
                    }, {root: true})
                //}, 1000)
            }
        }
    }
}