<template>
    <iframe v-show="show" ref="iframe" @mouseleave="onMouseleave" @mouseenter="onMouseenter"
            :class="{ hoverActive: isHoverActive, active: isActive }"/>
</template>

<script>
export default {
    name: "PageEditorContentIframe",
    props: {
        contentItem: Object
    },
    data() {
        return {
            promise: undefined
        }
    },
    computed: {
        pageContentId() {
            return this.contentItem.pageContentId
        },
        show() {
            return (
                    this.$store.state.pageEditor.activePageContentId !== this.pageContentId
                    && !this.$store.state.pageEditor.highlightContentAreaName
                )
                || this.$store.state.pageEditor.highlightPageContentId === this.pageContentId

        },
        isHoverActive() {
            return (
                    this.$store.state.pageEditor.hoverPageContentId
                    && this.$store.state.pageEditor.hoverPageContentId === this.pageContentId
                )
                || this.$store.state.pageEditor.highlightPageContentId === this.pageContentId
        },
        isActive() {
            return this.selectedContentAreaName || this.activePageContentId
                // Covers all content bar the one that is selected
                // Because the active content's content area iframe cannot be displayed,
                || (this.$store.state.pageEditor.activePageContentId && this.$store.state.pageEditor.activePageContentId !== this.pageContentId)
        }
    },
    mounted() {
        this.contentItem.iframe = this.$refs.iframe

        this.setIframeStyling()
        this.positionIframe()
    },
    destroyed() {
        clearInterval(this.promise)
    },
    methods: {
        onMouseenter() {
            this.$store.commit('pageEditor/hoverPageContentId', this.pageContentId)
            this.setIframeStyling()
        },
        onMouseleave() {
            this.$store.commit('pageEditor/hoverPageContentId', 0)
        },
        positionIframe() {
            this.$store.dispatch('pageEditor/positionIframe', {
                iframe: this.$refs.iframe,
                contentEl: this.contentItem.el
            })
        },
        setIframeStyling() {
            const iframe = this.$refs.iframe

            addLink(iframe, 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css')
            addLink(iframe, 'https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css')

            const bodyClassList = iframe.contentWindow.document.body.classList
            // Bootstrap sets the body's bg to white, but it must be transparent
            bodyClassList.add('bg-transparent')
            bodyClassList.add('lh-1')
            bodyClassList.add('overflow-hidden')

            function addLink(iframe, src) {
                const cssLink = document.createElement('link')
                cssLink.href = src
                cssLink.rel = 'stylesheet'
                //cssLink.type = 'text/css';
                iframe.contentDocument.head.appendChild(cssLink)
            }
        }
    }
}
</script>

<style scoped>
iframe {
    position: absolute;
    z-index: 999; /* Bootstrap's dropdown z-indexes are set to 1000 */
    /* z-index: 1054; Bootstrap's modals' z-indexes start at 1055 */
    /*z-index: 2147483647; https://stackoverflow.com/questions/491052/minimum-and-maximum-value-of-z-index; */
    overflow: hidden;
    /*border: 1px solid red;*/
}

.hoverActive {
    background: rgba(255, 255, 255, 0.75);
    border: 1px dashed !important;
    border-radius: 5px;
}

.active {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 5px;
}
</style>