<template>
    <Form :form-id="formId" :show-field-names="true" :show-legends="true"/>
</template>

<script>
import Form from "../../common/form/Form.vue"

export default {
    name: "PageEditorEditForm",
    components: {
        Form
    },
    props: {
        formId: String
    },
    computed: {
        isModified() {
            return this.$store.getters[this.formId + '/isModified']()
        },
        modifiedData() {
            return this.$store.getters[this.formId + '/modifiedData']()
        },
    },
    watch: {
        modifiedData() {
            if (this.isSaving) return
            this.isSaving = true

            this.$store.dispatch(this.formId + '/save')
                .then(o => {
                    this.isSaving = false

                    //this.$store.dispatch('pageEditor/reloadContent', {
                    //    pageContentId: this.pageContentId
                    //})
                })
        }
    }
}
</script>

<style scoped>

</style>