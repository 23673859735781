<template>
    <span>{{ value | numberFormat }}</span>
</template>

<script>
export default {
    name: "TableCellTypeNumber",
    props: {
        value: [Number, String],
    },
    filters: {
        numberFormat(value) {
            // Check if the number was a float, by searching for a decimal, and if so do not format it.
            // This is to work around a number formatting issue for numbers with decimal places. For example,
            // PG's carbonCalculation, set with a step of 0.00001 was being rounded to zero.
            return (value + '').includes('.') ? value : new Intl.NumberFormat().format(value)
        }
    }
}
</script>

<style scoped>
</style>