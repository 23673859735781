var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("p", { staticClass: "text-center" }, [
      _c("span", { staticClass: "btn-group" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-light",
            attrs: { disabled: _vm.selectedIds.length === 0, type: "button" },
            on: { click: _vm.sendArrivalFormEmails },
          },
          [_vm._v("\n                Arrival form email\n            ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-light",
            attrs: { disabled: _vm.selectedIds.length === 0, type: "button" },
            on: { click: _vm.sendEquipmentSalesEmails },
          },
          [_vm._v("\n                Equipment sales email\n            ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-light",
            attrs: { disabled: _vm.selectedIds.length === 0, type: "button" },
            on: { click: _vm.sendRequestReviewEmails },
          },
          [_vm._v("\n                Request review email\n            ")]
        ),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "btn-group" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-light",
            attrs: { disabled: _vm.selectedIds.length === 0, type: "button" },
            on: {
              click: function ($event) {
                return _vm.printDocument(104, "Arrival sheet", {})
              },
            },
          },
          [_vm._v("\n                Arrivals sheet\n            ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-light",
            attrs: { disabled: _vm.selectedIds.length === 0, type: "button" },
            on: {
              click: function ($event) {
                return _vm.printAirportTransferSheet(0)
              },
            },
          },
          [_vm._v("\n                Pickups\n            ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-light",
            attrs: { disabled: _vm.selectedIds.length === 0, type: "button" },
            on: {
              click: function ($event) {
                return _vm.printAirportTransferSheet(1)
              },
            },
          },
          [_vm._v("\n                Departures\n            ")]
        ),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-light",
          attrs: { disabled: _vm.selectedIds.length === 0, type: "button" },
          on: { click: _vm.archiveBookings },
        },
        [_vm._v("\n            Archive bookings\n        ")]
      ),
    ]),
    _vm._v(" "),
    _c("table", { staticClass: "table" }, [
      _c("thead", [
        _c(
          "tr",
          [
            _c("td", [
              _c("input", {
                attrs: { type: "checkbox" },
                domProps: { checked: _vm.allSelected },
                on: { click: _vm.toggleAllCheckboxes },
              }),
            ]),
            _vm._v(" "),
            _vm._l(_vm.columns, function (title, name) {
              return _c(
                "td",
                {
                  key: name,
                  on: {
                    click: function ($event) {
                      return _vm.sortColumns(name)
                    },
                  },
                },
                [
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.orderBy === name || _vm.orderBy === "-" + name,
                        expression:
                          "orderBy === name || orderBy === '-' + name",
                      },
                    ],
                    class: {
                      "bi-caret-down-fill":
                        name === _vm.orderBy && _vm.orderDesc,
                      "bi-caret-up-fill":
                        name === _vm.orderBy && !_vm.orderDesc,
                    },
                  }),
                  _vm._v(
                    "\n                " + _vm._s(title) + "\n            "
                  ),
                ]
              )
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.orderedRows, function (row) {
          return _c("tr", { key: row.id }, [
            _c("td", [
              _c("input", {
                attrs: { type: "checkbox" },
                domProps: { checked: _vm.selectedIds.indexOf(row.id) > -1 },
                on: {
                  click: function ($event) {
                    return _vm.selectCheckbox(row.id)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "a",
                {
                  attrs: { href: "" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.goToBooking(row.id)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(row.id) +
                      "\n                "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _vm._v(
                "\n                " + _vm._s(row.firstName) + "\n            "
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _vm._v(
                "\n                " + _vm._s(row.lastName) + "\n            "
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _vm.customerIdsWithConsecutiveBookings?.indexOf(row.customerId) >
              -1
                ? _c("i", { staticClass: "bi bi-check2" })
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("td", [
              _vm._v(
                "\n                " +
                  _vm._s(row.accommodation) +
                  "\n            "
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _vm._v(
                "\n                " +
                  _vm._s(row.tandemFlight ? "€200 extra" : "") +
                  "\n            "
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _vm._v(
                "\n                " +
                  _vm._s(row.trikeFlyingWithWheels ? "€300 extra" : "") +
                  "\n            "
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: row.price,
                    expression: "row.price",
                  },
                ],
                staticClass: "form-control input-sm",
                attrs: { size: "5" },
                domProps: { value: row.price },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(row, "price", $event.target.value)
                    },
                    function ($event) {
                      return _vm.saveAmount($event, row.id, "price")
                    },
                  ],
                },
              }),
            ]),
            _vm._v(" "),
            _c("td", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: row.depositAmountPaid,
                    expression: "row.depositAmountPaid",
                  },
                ],
                staticClass: "form-control input-sm",
                attrs: { size: "5" },
                domProps: { value: row.depositAmountPaid },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(row, "depositAmountPaid", $event.target.value)
                    },
                    function ($event) {
                      return _vm.saveAmount($event, row.id, "depositAmountPaid")
                    },
                  ],
                },
              }),
            ]),
            _vm._v(" "),
            _c("td", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: row.finalPaymentAmountPaid,
                    expression: "row.finalPaymentAmountPaid",
                  },
                ],
                staticClass: "form-control input-sm",
                attrs: { size: "5" },
                domProps: { value: row.finalPaymentAmountPaid },
                on: {
                  input: [
                    function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        row,
                        "finalPaymentAmountPaid",
                        $event.target.value
                      )
                    },
                    function ($event) {
                      return _vm.saveAmount(
                        $event,
                        row.id,
                        "finalPaymentAmountPaid"
                      )
                    },
                  ],
                },
              }),
            ]),
            _vm._v(" "),
            _c("td", [
              _vm._v(
                "\n                " +
                  _vm._s(
                    row.equipmentSalesEmailLastSent
                      ? _vm.getDate(row.equipmentSalesEmailLastSent * 1000)
                      : null
                  ) +
                  "\n            "
              ),
            ]),
            _vm._v(" "),
            _c("td", [
              _c(
                "button",
                {
                  staticClass: "btn btn-light btn-sm",
                  class: { active: row.notes },
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.showNotes(row)
                    },
                  },
                },
                [_vm._v("\n                    Edit\n                ")]
              ),
            ]),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }