import {Offcanvas} from "bootstrap"

export default {
    namespaced: true,
    state: {
        pageId: 0,
        pageIframe: undefined,
        contentAreas: [],
        contentItems: [],
        hoverContentAreaName: '',
        selectedContentAreaName: '',
        highlightContentAreaName: '',
        hoverPageContentId: 0,
        activePageContentId: 0,
        highlightPageContentId: 0,
        toolbarDropdown: undefined,
        offcanvas: undefined,
        inProgress: false,
        showEmptyContentAreas: false
    },
    mutations: {
        pageId(state, pageId) {
            state.pageId = pageId
        },
        pageIframe(state, pageIframe) {
            state.pageIframe = pageIframe
        },
        contentAreas(state, contentAreas) {
            state.contentAreas = contentAreas
        },
        contentItems(state, contentItems) {
            state.contentItems = contentItems
        },
        addContentItem(state, contentItem) {
            state.contentItems.push(contentItem)
        },
        hoverContentAreaName(state, contentAreaName) {
            state.hoverContentAreaName = contentAreaName
        },
        selectedContentAreaName(state, contentAreaName) {
            state.selectedContentAreaName = contentAreaName
        },
        highlightContentAreaName(state, contentAreaName) {
            state.highlightContentAreaName = contentAreaName
        },
        hoverPageContentId(state, pageContentId) {
            state.hoverPageContentId = pageContentId
        },
        activePageContentId(state, pageContentId) {
            state.activePageContentId = pageContentId
        },
        highlightPageContentId(state, pageContentId) {
            state.highlightPageContentId = pageContentId
        },
        toolbarDropdown(state, toolbarDropdown) {
            state.toolbarDropdown = toolbarDropdown
        }
    },
    actions: {
        // This is needed so that when the PageEditor loads, components don't generate errors
        // due to contentArea data  existing from previous pages.
        unset({commit}) {
            commit('contentAreas', [])
            commit('contentItems', [])
            //commit('pageId', 0)
            //commit('activePageContentId', 0)
        },
        deselectAll({commit}) {
            commit('hoverContentAreaName', '')
            commit('selectedContentAreaName', '')
            commit('hoverPageContentId', 0)
            commit('activePageContentId', 0)
        },
        init({state, commit, dispatch}, {
            router,
            iframe: pageIframe,
            area,
            pageContentId
        }) {
            dispatch('unset')
            dispatch('deselectAll')
            
            commit('selectedContentAreaName', area)
            if (pageContentId) {
                commit('activePageContentId', pageContentId)
                setTimeout(() => {
                    dispatch('scrollToContentIframeInstant', {pageContentId})
                })
            }
            
            commit('pageIframe', pageIframe)
            
            const body = pageIframe.contentDocument.body
            
            // Allow users to deselect the active iframe by clicking on the page's body
            body.addEventListener('click', () => {
                //dispatch('deselectAll')
                
                router.push({
                    name: 'siteTree',
                    params: {
                        pageOrContentId: state.pageId,
                    }
                })
                
                state.toolbarDropdown.click() // Hide the menu
            })
            
            window.addEventListener('resize', () => {
                dispatch('positionAllIframes')
            })
            
            let promise
            
            // Ensures that the iframes move smoothly with the body when scrolling long pages.
            pageIframe.contentDocument.addEventListener('scroll', () => {
                dispatch('positionAllIframes')
                
                /*
                dispatch('disableIframePointerEvents')
                
                if (promise) clearInterval(promise)
                promise = setTimeout(() => {
                    dispatch('enableIframePointerEvents')
                }, 200)
                */
            })
            
            const contentItems = []
            
            const contentAreas = []
            
            //const collection = body.getElementsByTagName('ccms-content-area')
            const collection = body.querySelectorAll('[data-ccms-content-area]')
            const tags = Array.from(collection)
            tags.forEach(tag => {
                //const contentArea = tag.parentNode
                const contentAreaName = tag.dataset.name
                //tag.remove()
                
                /*contentArea.addEventListener('mouseenter', () => {
                    // Don't show the iframe if mousing over the active content
                    if (state.selectedContentAreaName !== contentAreaName) {
                        commit('hoverContentAreaName', contentAreaName)
                    }
                })*/
                
                // Remove all text nodes, created by whitespace within content areas, as they mess with moving nodes
                //Array.from(tag.childNodes).filter(o => o.nodeType === 3).forEach(o => o.remove())
                
                let contentEls = Array.from(tag.childNodes)
                
                // Remove the <ccms-content-area> tag by replacing it with its children
                //tag.replaceWith(...tag.childNodes)
                
                contentEls.forEach((contentEl, i) => {
                    const pageContentId = parseInt(contentEl.id.substring(1))
                    
                    contentItems.push({
                        el: contentEl,
                        pageContentId,
                        iframe: undefined,
                        contentAreaName
                    })
                    
                    // Prevent the body element's click event from deactivating the active content when clicking the
                    // active element itself.
                    contentEl.addEventListener('click', (e) => {
                        if (state.activePageContentId === pageContentId) {
                            e.stopPropagation()
                        }
                    })
                })
                
                commit('contentItems', contentItems)
                
                contentAreas.push({
                    el: tag,
                    name: contentAreaName,
                    iframe: undefined
                })
            })
            
            // The tags are redundant so should be removed in case they conflict with the page's layout.
            // They must be removed using a separate loop otherwise it interferes
            //for (let tag of tags) { tag.remove() }
            // Looping the HTMLCollection returned by getElementsByTagName does not work and not all nodes are removed.
            // Converting the collection into an array _does_ work.
            //tags.forEach(o => o.remove())
            
            // $nextTick is required in order for PageEditorContentOverlay to refresh when the pageId changes.
            // Without this, overlays are missing from subsequent pages
            setTimeout(() => {
                commit('contentAreas', contentAreas)
            }, 100)
        },
        showOffcanvas({state, dispatch}) {
            const myOffcanvas = document.getElementById('offcanvasPageEditorContent')
            state.offcanvas = new Offcanvas(myOffcanvas)
            state.offcanvas.show()
        },
        hideOffcanvas({state}) {
            state.offcanvas.hide()
            state.offcanvas = undefined
        },
        moveUp({state, rootState, dispatch}, {pageContentId}) {
            if (state.inProgress) return
            state.inProgress = true
            
            const pageItems = rootState.pageContentData.items.filter(o => o.pageId === state.pageId)
            const item = pageItems.find(o => o.id === pageContentId)
            const contentAreaItems = pageItems.filter(o => o.contentArea === item.contentArea)
            const index = contentAreaItems.map(o => o.id).indexOf(pageContentId)
            const isFirst = index === 0
            
            if (isFirst) {
                const content = state.contentItems.find(o => o.pageContentId === pageContentId)
                const index = state.contentAreas.map(o => o.name).indexOf(content.contentAreaName)
                const previousContentArea = state.contentAreas[index - 1]
                if (!previousContentArea) {
                    state.inProgress = false
                    return
                }
                const previousContentAreaName = previousContentArea.name
                // Note: Remember that pageContentData includes unpublished content. So if unpublished content
                //       is NOT visible, the indexes may not seem correct.
                const contentAreaItems = pageItems.filter(o => o.contentArea === previousContentAreaName)
                const newDisplayOrder = contentAreaItems.length + 1
                
                dispatch('request/post', {
                    url: 'api/move-page-content',
                    postData: {
                        pageContentLinkId: pageContentId,
                        contentArea: previousContentAreaName,
                        displayOrder: newDisplayOrder
                    }
                }, {root: true})
                    .then(() => {
                        return dispatch('pageContentData/init', {}, {root: true})
                    })
                    .then(() => {
                        const parent = content.el.parentNode
                        parent.removeChild(content.el)
                        
                        const contentArea = state.contentAreas.find(o => o.name === previousContentAreaName)
                        contentArea.el.append(content.el)
                        content.contentAreaName = previousContentAreaName
                        
                        dispatch('positionAllIframes')
                        state.inProgress = false
                        dispatch('scrollToContentIframe', {pageContentId})
                    })
            } else {
                dispatch('request/post', {
                    url: 'api/move-page-content',
                    postData: {
                        pageContentLinkId: pageContentId,
                        contentArea: item.contentArea,
                        displayOrder: item.displayOrder - 1
                    }
                }, {root: true})
                    .then(() => {
                        return dispatch('pageContentData/init', {}, {root: true})
                    })
                    .then(() => {
                        const content = state.contentItems.find(o => o.pageContentId === pageContentId)
                        const previousEl = content.el.previousSibling
                        previousEl?.before(content.el)
                        
                        dispatch('positionAllIframes')
                        state.inProgress = false
                        dispatch('scrollToContentIframe', {pageContentId})
                    })
            }
        },
        moveDown({state, rootState, dispatch}, {pageContentId}) {
            if (state.inProgress) return
            state.inProgress = true
            
            const pageItems = rootState.pageContentData.items.filter(o => o.pageId === state.pageId)
            const item = pageItems.find(o => o.id === pageContentId)
            const contentAreaItems = pageItems.filter(o => o.contentArea === item.contentArea)
            const index = contentAreaItems.map(o => o.id).indexOf(pageContentId)
            const isLast = index === contentAreaItems.length - 1
            
            if (isLast) {
                const content = state.contentItems.find(o => o.pageContentId === pageContentId)
                const index = state.contentAreas.map(o => o.name).indexOf(content.contentAreaName)
                const nextContentArea = state.contentAreas[index + 1]
                if (!nextContentArea) {
                    state.inProgress = false
                    return
                }
                const nextContentAreaName = nextContentArea.name
                
                dispatch('request/post', {
                    url: 'api/move-page-content',
                    postData: {
                        pageContentLinkId: pageContentId,
                        contentArea: nextContentAreaName,
                        displayOrder: 1
                    }
                }, {root: true})
                    .then(() => {
                        return dispatch('pageContentData/init', {}, {root: true})
                    })
                    .then(() => {
                        // Remove the content from the DOM
                        const parent = content.el.parentNode
                        parent.removeChild(content.el)
                        
                        // Insert the content into the top of the next content area
                        const contentArea = state.contentAreas.find(o => o.name === nextContentAreaName)
                        contentArea.el.prepend(content.el)
                        content.contentAreaName = nextContentAreaName
                        
                        dispatch('positionAllIframes')
                        state.inProgress = false
                        dispatch('scrollToContentIframe', {pageContentId})
                    })
            } else {
                dispatch('request/post', {
                    url: 'api/move-page-content',
                    postData: {
                        pageContentLinkId: pageContentId,
                        contentArea: item.contentArea,
                        displayOrder: item.displayOrder + 1
                    }
                }, {root: true})
                    .then(() => {
                        return dispatch('pageContentData/init', {}, {root: true})
                    })
                    .then(() => {
                        const content = state.contentItems.find(o => o.pageContentId === pageContentId)
                        const nextEl = content.el.nextSibling
                        nextEl?.after(content.el)
                        
                        dispatch('positionAllIframes')
                        state.inProgress = false
                        dispatch('scrollToContentIframe', {pageContentId})
                    })
            }
        },
        removeContent({state, dispatch, commit}, {pageContentId}) {
            dispatch('modals/show', {
                componentName: 'ConfirmModal',
                obj: {
                    modalTitle: 'Are you sure?',
                    //modalBody: '<p>Deleting <strong>' + obj.title + '</strong>.</p>',
                    onConfirm: () => {
                        // todo This should be replaced by a method in pageContentData which also calls init()
                        dispatch('request/post', {
                            url: 'api/delete-page-content-link-id',
                            postData: {
                                pageContentLinkId: pageContentId
                            }
                        }, {root: true})
                            .then(() => {
                                // Remove the node
                                const content = state.contentItems.find(o => o.pageContentId === pageContentId)
                                content.el.remove()
                                
                                // Remove the content's data from VueX to update Vue components
                                const i = state.contentItems.map(o => o.pageContentId).indexOf(pageContentId)
                                state.contentItems.splice(i, 1)
                                
                                dispatch('deselectAll')
                                
                                dispatch('pageContentData/init', {}, {root: true})
                            })
                    }
                }
            }, {root: true})
        },
        positionIframe({state}, {iframe, contentEl}) {
            if (iframe) {
                const rect = contentEl.getBoundingClientRect()
                
                iframe.style.top = rect.top + 'px'
                iframe.style.left = rect.left + 'px'
                iframe.style.width = rect.width + 'px'
                iframe.style.height = rect.height + 'px'
            }
        },
        positionAllIframes({state, dispatch}) {
            state.contentAreas.forEach(o => {
                dispatch('positionIframe', {
                    iframe: o.iframe,
                    contentEl: o.el
                })
            })
            
            state.contentItems.forEach(o => {
                dispatch('positionIframe', {
                    iframe: o.iframe,
                    contentEl: o.el
                })
            })
        },
        selectContent({commit, dispatch}, {router, pageId, pageContentId}) {
            router.push({
                    name: 'pageEditor',
                    params: {
                        pageId: pageId
                    },
                    query: {
                        id: pageContentId
                    }
                })
                .then(() => {
                    dispatch('positionAllIframes')
                })
        },
        selectContentArea({commit, dispatch}, {router, pageId, contentAreaName}) {
            router.push({
                    name: 'pageEditor',
                    params: {
                        pageId: pageId
                    },
                    query: {
                        area: contentAreaName
                    }
                })
                .then(() => {
                    //dispatch('positionAllIframes')
                })
        },
        scrollToContentAreaIframe({state}, {contentAreaName}) {
            const contentArea = state.contentAreas.find(o => o.name === contentAreaName)
            contentArea.el.scrollIntoView()
        },
        scrollToContentIframe({state}, {pageContentId}) {
            const content = state.contentItems.find(o => o.pageContentId === pageContentId)
            content?.el.scrollIntoView()
        },
        scrollToContentIframeInstant({state}, {pageContentId}) {
            const content = state.contentItems.find(o => o.pageContentId === pageContentId)
            content?.el.scrollIntoView({behavior: 'instant'})
        },
        showAddContentModal({state, dispatch, commit}, {contentAreaName}) {
            dispatch('modals/show', {
                componentName: 'AddContentModal',
                obj: {
                    onSelect: o => {
                        let pageContentId
                        
                        dispatch('request/post', {
                            url: 'api/save-content-to-page',
                            postData: {
                                pageId: state.pageId,
                                contentId: o.contentId,
                                contentArea: contentAreaName
                            }
                        }, {root: true})
                            .then((o) => {
                                pageContentId = o.data.pageContentId
                                
                                return dispatch('pageContentData/init', {}, {root: true})
                            })
                            .then(() => {
                                dispatch('deselectAll')
                                commit('activePageContentId', pageContentId)
                                
                                return dispatch('request/get', {
                                    url: 'api/page-editor/content/' + pageContentId,
                                }, {root: true})
                            })
                            .then((o) => {
                                // Assigning the HTML to an element converts it into a DOM node
                                const div = document.createElement('div')
                                div.innerHTML = o.data.html
                                const contentEl = div.firstChild
                                
                                // Move the node to the content area
                                const contentAreaEl = state.contentAreas.find(o => o.name === contentAreaName).el
                                contentAreaEl.append(contentEl)
                                
                                // Add the node
                                commit('addContentItem', {
                                    el: contentEl,
                                    pageContentId,
                                    iframe: undefined,
                                    contentAreaName
                                })
                            })
                    }
                }
            }, {root: true})
        },
        reloadContent({state, dispatch}, {pageContentId}) {
            dispatch('request/get', {
                url: 'api/page-editor/content/' + pageContentId,
            }, {root: true})
                .then(o => {
                    // Assigning the HTML to an element converts it into a DOM node
                    const div = document.createElement('div')
                    div.innerHTML = o.data.html
                    const newContentEl = div.firstChild
                    
                    const content = state.contentItems.find(o => o.pageContentId === pageContentId)
                    
                    content.el.replaceWith(newContentEl)
                    content.el = newContentEl
                    
                    // todo This has been disabled because it scrolls even when the element is within the viewport
                    //      This is really annoying! For example, you've scrolled down to see the bottom of the CT as it
                    //      changes, but it keeps scrolling to the top. This should be updated to only scroll if the
                    //      element is completely out of the viewport.
                    //dispatch('scrollToContentIframe', {pageContentId})
                })
        },
        // This should not be used as it causes the page to flicker, the current selection to be unset etc.
        // I've left it in place in case it's useful for testing.
        reloadPage({state, commit, dispatch}) {
            const pageId = state.pageId
            
            // Refresh the page editor by toggling the page ID
            commit('pageId', 0)
            
            dispatch('unset')
            setTimeout(() => {
                commit('pageId', pageId)
            }, 200)
        },
        disableIframePointerEvents({state}) {
            state.contentAreas.forEach(o => {
                o.iframe.style.pointerEvents = 'none'
            })
            
            state.contentItems.forEach(o => {
                o.iframe.style.pointerEvents = 'none'
            })
        },
        enableIframePointerEvents({state}) {
            state.contentAreas.forEach(o => {
                o.iframe.style.pointerEvents = ''
            })
            
            state.contentItems.forEach(o => {
                o.iframe.style.pointerEvents = ''
            })
        }
    }
}