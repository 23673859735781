<template>
    <div v-if="!field.hidden && !field.replaceFormgroup" v-show="field.visibility !== 'hidden'"
         :class="['n-' + field.name, field.class]" data-form-group>
        <template v-if="field.type !== 'checkbox'">
            <label :for="field.id" class="form-label" :class="{'mb-1':field.helpText}">{{ field.label }}</label>
            <template v-if="!hideHelpText">
                <p v-if="field.helpText" v-html="field.helpText" class="form-text mt-0 mb-2"></p>
                <Tooltip v-else :hide="100" :show="250" :tooltip="field.helpText" :trigger="'hover'"/>
            </template>
        </template>

        <div class="has-validation">
            <div v-if="field.type === 'checkbox'" class="form-check">
                <FormControl :class="{'is-invalid': field.error}" :field="field" :form-id="formId"/>
                <label :for="field.id" class="form-check-label">{{ field.label }}</label>
                <template v-if="!hideHelpText">
                    <p v-if="field.helpText" v-html="field.helpText" class="form-text mt-0"></p>
                    <Tooltip v-else :hide="100" :show="250" :tooltip="field.helpText" :trigger="'hover'" class="ms-1"/>
                </template>
            </div>
            <FormControl v-else :class="{'is-invalid': field.error}" :field="field" :form-id="formId"/>

            <div v-if="field.error" class="invalid-feedback" v-html="field.error"/>
            <div v-else-if="field.required && !field.readonly && !currentData[field.name] && !field.error"
                 class="form-text">Required
            </div>
        </div>
    </div>

    <div v-else-if="field.replaceFormgroup">
        <FormControl :field="field" :form-id="formId"/>
    </div>
</template>

<script>
import Tooltip from '../Tooltip'
import FormControl from './FormControl'

export default {
    name: "FormGroup",
    components: {
        Tooltip,
        FormControl
    },
    props: {
        formId: String,
        field: Object,
        hideHelpText: false
    },
    computed: {
        currentData() {
            return this.$store.state[this.formId].currentData
        },
        betaMode() {
            return this.$store.state.betaMode
        }
    },
    created() {
        this.field.label = this.field.label || this.field.title

        // Hide dev only fields unless betaMode mode is active.
        if (
            this.field.devOnly
            && !this.$store.state.betaMode
        ) {
            this.field.hidden = true
        }
    }
}
</script>

<style scoped>
.form-text {
    max-width: 600px;
}

/* Their sizes must match so that when "required" is replaced with an error the layout doesn't shift */
.form-text,
.invalid-feedback {
    font-size: 13px;
}
</style>