<template>
    <button v-if="isSet" class="btn btn-link" type="button" @click="onClick">
        <i class="bi-x-lg"/>
    </button>
</template>

<script>
export default {
    name: "SearchFilterItemResetBtn",
    props: {
        filterId: Number,
        viewId: String,
        filter: Object
    },
    computed: {
        filters() {
            return this.$store.state[this.viewId].filters
        },
        isSet() {
            return this.filters.advancedFilter[this.filterId] !== undefined
        }
    },
    methods: {
        onClick() {
            this.$store.commit(this.viewId + '/setFilter', {
                id: this.filterId,
                value: undefined
            })

            const filterData = this.filter.filterData

            // When resetting the IS and IS NOT SET options, as they have no value fields, the option menu must
            // also be reset.
            if (
                filterData.condition === 'is set'
                || filterData.condition === 'is not set'
            ) {
                filterData.condition = ''

            } else if (
                filterData.type === 'relationshipOneToMany'
                && (
                    filterData.condition === 'one is set'
                    || filterData.condition === 'one is not set'
                )
            ) {
                filterData.condition = ''

            } else if (
                filterData.type === 'relationshipManyToMany'
                && (
                    filterData.condition === 'many is set'
                    || filterData.condition === 'many is not set'
                )
            ) {
                filterData.condition = ''
            }
        }
    }
}
</script>

<style scoped>

</style>