<template>
    <div>
        <FormControlTypeRelationshipManyToMany ref="myChild" :current-data="currentData" :field="field"
                                               :form-id="formId"/>

        <p class="mt-2">
            <button class="btn btn-light" v-bind:disabled="btnDisabled" @click="sendEmail">
                Send email
            </button>
        </p>
    </div>
</template>

<script>
import FormControlTypeRelationshipManyToMany from '../form-control-type/FormControlTypeRelationshipManyToMany'

export default {
    name: "OrdersEmails",
    components: {
        FormControlTypeRelationshipManyToMany
    },
    props: {
        formId: String,
        field: Object,
        currentData: Object,
    },
    data() {
        return {
            btnDisabled: false
        }
    },
    computed: {
        tableName() {
            return this.$store.state[this.formId].tableName
        },
        id() {
            return this.$store.state[this.formId].id
        },
        urlSegment() {
            return this.field.name === 'customerEmailMessageId' ? 'customer' : 'internal'
        }
    },
    asyncComputed: {},

    methods: {
        sendEmail() {
            if (!confirm('Are you sure?')) return

            this.btnDisabled = true

            this.$store.dispatch('request/get', {
                    url: this.$store.state.websiteEndpoint +
                        '/controller/order-email/' + this.id + '/' + this.urlSegment,
                })
                .then(() => {
                    this.btnDisabled = false

                    this.$refs.myChild.loadIds()
                })
        }
    },
}
</script>

<style scoped>

</style>