<template>
    <div v-if="offcanvasTitle" class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasPageEditorEditPage"
         aria-labelledby="offcanvasPageEditorEditPageLabel">
        <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasPageEditorEditPageLabel">{{ offcanvasTitle }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>

        <div class="offcanvas-body pb-5">
            <PageEditorEditFormAndSidebar :table-name="tableName" :id="id" :key="tableName + '.' + id"/>

            <p class="pb-2"/>
        </div>

        <div class="offcanvas-footer"/>
    </div>
</template>

<script>
import {Offcanvas} from "bootstrap"

import Button from "../../common/Button.vue"
import PageEditorEditFormAndSidebar from "./PageEditorEditFormAndSidebar.vue"

export default {
    name: "PageEditorToolbarEditOffcanvas",
    components: {
        PageEditorEditFormAndSidebar,
        Button
    },
    computed: {
        pageId() {
            return this.$store.state.pageEditor.pageId
        },
        pageContentId() {
            return this.$store.state.pageEditor.activePageContentId
        },
        contentId() {
            return this.$store.state.pageContentData.items.find(o => o.id === this.pageContentId)?.contentId
        },
        tableName() {
            return this.contentId ? 'content' : 'pages'
        },
        id() {
            return this.contentId || this.pageId
        },
        offcanvasTitle() {
            return this.item?.title
        }
    },
    asyncComputed: {
        item() {
            if (this.id) {
                return this.$store.dispatch('itemData/get', {
                    tableName: this.tableName,
                    id: this.id
                })
            }
        }
    }
}
</script>

<style scoped>
.offcanvas {
    width: 100%;
}

@media (min-width: 768px) {
    .offcanvas {
        width: 75%;
    }
}

@media (min-width: 992px) {
    .offcanvas {
        width: 50%;
    }
}
</style>