var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("ul", { staticClass: "nav nav-tabs", attrs: { role: "tablist" } }, [
      _c(
        "li",
        {
          staticClass: "nav-item",
          attrs: { role: "presentation" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              _vm.activeTab = "preview"
            },
          },
        },
        [
          _c(
            "a",
            {
              staticClass: "nav-link",
              class: { active: _vm.activeTab === "preview" },
              attrs: { href: "#" },
            },
            [_vm._v("Preview")]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "li",
        {
          staticClass: "nav-item",
          attrs: { role: "presentation" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              _vm.activeTab = "html"
            },
          },
        },
        [
          _c(
            "a",
            {
              staticClass: "nav-link",
              class: { active: _vm.activeTab === "html" },
              attrs: { href: "#" },
            },
            [_vm._v("HTML")]
          ),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "tab-content" }, [
      _vm.activeTab === "preview"
        ? _c(
            "div",
            {
              staticClass: "tab-pane",
              class: { active: _vm.activeTab === "preview" },
              attrs: { id: "preview", role: "tabpanel" },
            },
            [
              _c("iframe", {
                ref: "iframe",
                staticStyle: {
                  width: "100%",
                  "min-height": "250px",
                  border: "solid #ddd",
                  "border-width": "0 1px 1px 1px",
                },
                attrs: { srcdoc: _vm.iframeHtml },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.activeTab === "html"
        ? _c(
            "div",
            {
              staticClass: "tab-pane",
              class: { active: _vm.activeTab === "html" },
              attrs: { id: "html", role: "tabpanel" },
            },
            [
              _c("FormControlTypeCodeEditor", {
                attrs: {
                  "current-data": _vm.currentData,
                  field: _vm.field,
                  "form-id": _vm.formId,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }