<template>
    <form ref="form">
        <!--<div v-if="formId" v-for="(field, i) in fields" :key="i" class="mb-3">
            <label>{{ field.title }}</label>
            <FormControl :field="field" :form-id="formId"/>
        </div>-->

        <FormGroup class="mb-3" v-for="(field, i) in fields" :key="i" :form-id="formId" :field="field" :hide-help-text="true"/>
    </form>
</template>

<script>
import FormControl from "../../common/form/FormControl.vue"
import FormGroup from "../../common/form/FormGroup.vue"

export default {
    name: "PageEditorContentPanelForm",
    components: {
        FormGroup,
        FormControl
    },
    props: {
        // A single prop is used with a combination of both the tableName and id e.g. component_123.45, so that
        // the form can be initialized only when this single property changes. When the tableName and id were provided
        // separately, it resulted in the form sometimes initialising with the wrong tableName and id combination as one
        // property changed before the other.
        tableNameId: String
    },
    data() {
        return {
            formId: 'pageEditorForm',
            isSaving: false
        }
    },
    computed: {
        tableName() {
            return this.tableNameId.split('.')[0]
        },
        id() {
            return parseInt(this.tableNameId.split('.')[1])
        },
        fields() {
            return this.$store.state[this.formId]?.fields
                .filter(o => o.columnName !== 'contentId')
            //.filter(o => o.type !== 'texteditor')
        },
        isModified() {
            return this.$store.getters[this.formId + '/isModified']()
        },
        modifiedData() {
            return this.$store.getters[this.formId + '/modifiedData']()
        },
        pageContentId() {
            return this.$store.state.pageEditor.activePageContentId
        }
    },
    mounted() {
        this.$store.dispatch(this.formId + '/init', {
            tableName: this.tableName,
            id: this.id
        })

        this.$store.commit(this.formId + '/setFormEl', this.$refs.form)
    },
    watch: {
        modifiedData() {
            if (this.isSaving) return
            this.isSaving = true

            this.$store.dispatch(this.formId + '/save')
                .then(o => {
                    this.isSaving = false

                    this.$store.dispatch('pageEditor/reloadContent', {
                        pageContentId: this.pageContentId
                    })
                })
        }
    }
}
</script>

<style scoped>
form {
    zoom: 85%
}

select {
    max-width: 100% !important;
}
</style>