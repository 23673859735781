<template>
    <div>
        <!-- This is replaced by the editor, CKEditor uses its value to populate the editor -->
        <textarea ref="textarea" style="display: none">{{ currentData[field.name] }}</textarea>

        <!-- This is required for validation. It remains in the DOM and so can be validated -->
        <textarea :id="field.id" v-model="currentData[field.name]" :name="field.name" :required="field.required"
                  style="display: none"/>

        <iframe v-if="showIframe && iframeHtml !== undefined" ref="iframe" :srcdoc="iframeHtml"
                style="width: 100%; height: 200px; border: 1px solid; border-radius: 5px" :style="iframeStyle"/>
    </div>
</template>

<script>
export default {
    name: "FormControlTypeTexteditor",
    props: {
        formId: String,
        field: Object,
        currentData: Object,
    },
    data() {
        return {
            showIframe: true,
            editor: undefined,
        }
    },
    computed: {
        iframeHtml() {
            return this.getIframeHtml()
        },
        componentIds() {
            return this.$store.state.components.componentIds
        },
        tableName() {
            return this.$store.state[this.formId].tableName
        },
        id() {
            return this.$store.state[this.formId].id
        },
        cssFramework() {
            return this.$store.state.settings.config.css_framework
        },
        isDarkMode() {
            return this.$store.getters['user/pref']('darkMode')
        },
        iframeStyle() {
            return this.isDarkMode
                ? 'border-color: #495057'
                : 'border-color: #ddd'
        }
    },
    created() {
        let content = this.currentData[this.field.name]
        if (
            typeof content === 'undefined'
            || content === null
        ) {
            this.$store.commit(this.formId + '/presetData', {
                name: this.field.name,
                value: ''
            })

        } else if (content.indexOf('/asset/file/') !== -1) {
            // todo - Remove once all sites using /asset/file/<id>/
            let search = 'https://' + this.$store.state.sitename + '.creativecms.io/asset/file/'
            let replace = '/asset/file/'
            content = content.replace(new RegExp(search, 'g'), replace)

            this.$store.commit(this.formId + '/presetData', {
                name: this.field.name,
                value: content
            })
        }
    },
    mounted() {
        this.applyBodyClickEvent()
    },
    destroyed() {
        this.destroyEditor()
    },
    watch: {
        isDarkMode(newValue) {
            // This was a failed attempt to get the editor to recreate in the new mode.
            //this.destroyEditor()
            //this.$nextTick(() => {
            //    this.createEditor()
            //})
        }
    },
    methods: {
        getIframeHtml() {
            let html = this.currentData[this.field.name]

            if (!html) {
                html = '<em style="color: #999; font-size: 14px">Click to edit.</em>'
            }

            let style = ''
            if (this.isDarkMode) {
                //style = '<style>body { background-color: black; color: white}</style>'
            }
            
            // We don't render the CKEditor on load as it's pretty slow, and this gets especially bad if there are
            // more than one editor. So instead we display the content in an iframe which gets replaced by an
            // editor when the user clicks.
            //let iframeHtml = '<link href="https://cdn.creativecms.io/bootstrap/3.3.2/css/bootstrap.min.css" rel="stylesheet">';
            return '' +
                '<link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css" rel="stylesheet">' +
                '<link href="ckeditor/contents.css" rel="stylesheet">' +
                '<link href="ckeditor-custom/styles.css" rel="stylesheet">' +
                //'<link href="ckeditor-custom/dark-mode.css" rel="stylesheet">' +

                // Image's src are set as /asset/file/<id>, so we must prefix them with the website's URL.
                // Must be placed after the styles above so that those remain relative.
                '<base href="' + this.$store.state.ccmsSchemeAndHost + '">' +

                style +

                '<html data-bs-theme="dark" lang="en">' + html + '</html>' +

                // overlay to prevent links etc.
                '<div style="position: fixed; top: 0; left: 0; width: 100%; height: 100%;"></div>'
        },
        applyBodyClickEvent() {
            let iframe = this.$refs.iframe
            iframe.onload = () => {
                iframe.contentWindow.document.onclick = this.createEditor
            }
        },
        createEditor() {
            this.showIframe = false

            let textarea = this.$refs.textarea

            let version = this.cssFramework ? this.cssFramework.split('@').pop() : '3.3.7'
            let majorVersion = parseInt(version.split('.')[0]) || 3
            let cssSrc = 'https://cdn.jsdelivr.net/npm/bootstrap@' + version + '/dist/css/bootstrap.min.css'

            const origin = window.location.origin // e.g. http://ccms5.ccms.io:8081

            const contentCss = [
                cssSrc,
                origin + '/ckeditor/contents.css',
                origin + '/ckeditor-custom/styles.css'
                // This was an attempt to load a site's CSS into the editor. It works, but it conflicts with the editor's menus.
                //'http://ccms1-1-business.creativecms.io:8000/asset/css/*'
            ]
            if (this.isDarkMode) {
                //contentCss.push(origin + '/ckeditor-custom/dark-mode.css')
            }

            this.editor = CKEDITOR
                .replace(textarea, {
                    skin: this.isDarkMode ? 'moono-dark' : 'moono',
                    baseHref: this.$store.state.websiteEndpoint,
                    stylesSet: this.getStylesSet(majorVersion),
                    contentsCss: contentCss,
                    customConfig: '/ckeditor-custom/config.js',
                    openDocumentModal: this.openDocumentModal,
                    openImageModal: this.openImageModal,
                    openInternalLinkModal: this.openInternalLinkModal,
                    customParams: {
                        // These are required by the file uploader for replacing image element's src
                        // with the CDN src once the file has been uploaded to the CDN.
                        // See: image-selection-modal directive.
                        tableName: this.tableName,
                        fieldName: this.field.name,
                        id: this.id,
                    }
                })

            this.editor.on('change', () => {
                this.currentData[this.field.name] = this.editor.getData().trim()
            })
        },
        openDocumentModal(editor, applyLink, href) {
            let selectedId = href ? parseInt(href.match(/\d+/)[0]) : false

            // todo - This depends on various services being accessible in the global scope, but this probably isn't a
            //  good idea.

            let modal = this.$store.dispatch('modals/show', {
                componentName: 'SelectListModal',
                obj: {
                    listingName: 'documents',
                    selectedIds: selectedId ? [selectedId] : [],
                    onSelect: (selectedIds) => {
                        let id = selectedIds.pop()
                        if (id) {
                            let href = '[DID:' + id + ']'
                            applyLink(editor, href)
                        }
                        modal.then((obj) => {
                            this.$store.dispatch('modals/remove', obj.index)
                        })
                    }
                }
            })
        },
        openImageModal(editor, image) {
            this.$store.dispatch('modals/show', {
                componentName: 'ImageSelectionModal',
                obj: {
                    editor: editor,
                    image: image
                }
            })
        },
        openInternalLinkModal(editor, pageId, contentId, applyLink) {
            this.$store.dispatch('modals/show', {
                componentName: 'SiteTreeModal',
                obj: {
                    linkOptions: ['i', 'e', 't', 'p', 'c'],
                    pageId: pageId,
                    contentId: contentId,
                    onSelect(obj) {
                        let pageId = obj.pageId
                        let contentId = obj.pageContentLinkId
                        let href = false
                        if (pageId) {
                            href = '[PID:' + pageId + ']'
                        } else if (contentId) {
                            href = '[CID:' + contentId + ']'
                        }

                        applyLink(editor, href)
                    }
                }
            })
        },
        destroyEditor() {
            // stackoverflow /questions/19328548/ckeditor-uncaught-typeerror-cannot-call-method-unselectable-of-null-in-emberj
            if (this.editor) {
                this.editor.removeAllListeners()
                CKEDITOR.remove(this.editor)
            }

            /*
            // CKEditor bug fix. When the editor is maximised, and the history back button is
            // used, the editor fails to remove styling it applies to the document's HTML and
            // Body elements. So we must force remove this whenever editors are destroyed.
            $('html,body').css({
                position: '',
                zIndex: '',
                width: '',
                height: ''
            });
            */
        },
        getStylesSet(majorVersion) {
            const stylesSet = [
                {name: 'Code', element: 'code'},
                {name: 'Lead', element: 'p', attributes: {'class': 'lead'}},
            ]

            let btns
            switch (majorVersion) {
                case 5:
                    btns = ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark', 'link']
                    break
                case 3:
                    btns = ['default', 'primary']
                    break
            }

            if (btns) {
                btns.forEach((btn) => {
                    const name = 'Button (' + btn + ')'
                    const className = 'btn btn-' + btn
                    stylesSet.push({name: name, element: 'a', attributes: {'class': className}})
                })
            }

            return stylesSet
        }
    }
}
</script>

<style>
/* Remove CKEditor's light border when in dark mode */
[data-bs-theme="dark"] .cke_chrome {
    border: none !important;
}

[data-bs-theme="dark"] .cke_1 .cke_top {
    border: none !important;
}

[data-bs-theme="dark"] .cke_bottom {
    background-image: none !important;
    background: #333 !important;
    border-top: 1px solid #333 !important;
    box-shadow: none !important;
}

[data-bs-theme="dark"] .cke_path_item {
    color: #fff !important;
    text-shadow: none !important;
}

[data-bs-theme="dark"] a.cke_path_item:hover {
    background: #666 !important;
    border-bottom: none !important;
    box-shadow: none !important;
}

/* Fix for source view presenting white text on white background */
[data-bs-theme="dark"] .cke_source {
    color: #000 !important;
    white-space: wrap !important;
}
</style>