<template>
    <iframe ref="iframe" :srcdoc="iframeSrcdoc" @load="init" class="flex-fill"></iframe>
</template>

<script>
export default {
    name: "PageEditorIframe",
    props: {
        pageId: Number
    },
    asyncComputed: {
        iframeSrcdoc() {
            if (this.pageId) {
                return this.$store.dispatch('request/get', {
                        url: 'controller/page-editor/' + this.pageId
                    })
                    .then(o => {
                        return o.data.html
                    })
            }
        }
    },
    destroyed() {
        this.$store.dispatch('pageEditor/unset')
    },
    methods: {
        init() {
            this.$store.dispatch('pageEditor/init', {
                router: this.$router,
                iframe: this.$refs.iframe,
                area: this.$route.query.area,
                pageContentId: parseInt(this.$route.query.id)
            })
        }
    }
}
</script>

<style scoped>

</style>